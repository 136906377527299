import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/LeadParagraph';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "localisation",
      "style": {
        "position": "relative"
      }
    }}>{`Localisation`}<a parentName="h1" {...{
        "href": "#localisation",
        "aria-label": "localisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Localising services for different languages makes City of Helsinki services more approachable and easy to understand
  for wider audiences.
    </LeadParagraph>
    <p>{`Here are some recommendations for localising content and navigation:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`The default language of Helsinki digital services is Finnish (FI).`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`It is recommended that Helsinki digital services follow the Finnish date and time locale even if the user is using some other language. See the `}<InternalLink href="/foundation/guidelines/data-formats" mdxType="InternalLink">{`HDS data format guidelines`}</InternalLink>{` for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Content should also be provided at least in Swedish (SV) and English (EN), if possible.`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Finnish (FI), Swedish (SV), and English (EN) languages should be treated as equal in content and language menus. For these languages, it is recommended to provide full content.`}</li>
          <li parentName="ul">{`For the English (EN) locale, British English spelling should be used.`}</li>
          <li parentName="ul">{`For the Swedish (SV) locale, the Helsingfors version of the framed logo should be used. Other languages use the default Helsinki framed logo. See more in the `}<InternalLink href="/foundation/visual-assets/logo" mdxType="InternalLink">{`logo guidelines.`}</InternalLink></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Additional languages`}</strong>{`, sign language, and plain language versions of content can be added depending on the targeted user group of the service. The content provided in additional languages can be a subset or a summary of the main content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Let the user select their preferred language.`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Do not auto-localise content or menus unless the user has picked a default language for themself.`}</li>
          <li parentName="ul">{`For logged-in users, the language selection can be stored in user information.`}</li>
          <li parentName="ul">{`For non-logged-in users, the selection made in the language picker can be stored with a cookie.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`When the user switches languages, the page context should be preserved.`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`When the user switches language on a sub-page, the corresponding page should be shown, instead of redirecting the user to the front page.`}</li>
          <li parentName="ul">{`If a localised version of the content is not available, it is recommended to inform the user and provide possible next actions, instead of redirecting to the front page.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`The preferred method for switching languages is with the language picker action in `}<InternalLink href="/components/navigation" mdxType="InternalLink">{`HDS Navigation component.`}</InternalLink></strong></p>
        <ul parentName="li">
          <li parentName="ul">{`If there are less than three available languages and the services navigation is simple, available languages can be listed as links in the navigation bar action row instead of a language select dropdown.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use language code (`}<inlineCode parentName="strong">{`FI`}</inlineCode>{`, `}<inlineCode parentName="strong">{`SV`}</inlineCode>{`, `}<inlineCode parentName="strong">{`EN`}</inlineCode>{`...) to represent locales`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Do not use flags. Languages are not always country-specific.`}</li>
          <li parentName="ul">{`The currently selected language (for example in navigation language picker) can be indicated with language code alone.`}</li>
          <li parentName="ul">{`When listing languages for selection it is advised to also write out the locale in its corresponding language. Here are some common example locales:`}</li>
        </ul>
      </li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Locale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Language code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Verbose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finnish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FI`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="fi">{`Suomeksi (FI)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Swedish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SV`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="sv">{`På svenska (SV)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EN`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="en">{`In English (EN)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`German`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="de">{`Auf Deutsch (DE)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="fr">{`En français (FR)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Estonian`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ET`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="et">{`Eesti keelel (ET)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ES`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="es">{`En español (ES)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Northern Sami`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="se_no">{`Davvisámegillii (SE)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inari Sami`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SMI`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="se_fi">{`Anarâškielân (SMI)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skolt Sami`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SKS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="se_se">{`Nuõrttsää´mǩiõ´lle (SKS)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Somali`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SO`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="so">{`Af soomaali (SO)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RU`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="ru">{`По-русски (RU)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JA`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="ja">{`日本語 (JA)`}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ZH`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="zh">{`中文`}</span>{` (ZH)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="ar" dir="rtl">{`بالعربية`}</span>{` (AR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fārsī`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FA`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><span lang="fa" dir="rtl">{`فارسی`}</span>{` (FA)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Language code and verbose for the most used languages in the Helsinki services]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      